var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "slide-right", mode: "out-in" } },
    [
      _c(
        "v-layout",
        { key: _vm.currentPage, attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [_vm._t("default")], 2),
          _c("custom-pagination", {
            key: _vm.currentPage,
            attrs: { properties: _vm.pagination },
            on: { next: _vm.next, previous: _vm.previous }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }