var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", md4: "", lg2: "" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Show drilldowns" },
                            model: {
                              value: _vm.showDrilldown,
                              callback: function($$v) {
                                _vm.showDrilldown = $$v
                              },
                              expression: "showDrilldown"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Show animation" },
                            model: {
                              value: _vm.showAnimation,
                              callback: function($$v) {
                                _vm.showAnimation = $$v
                              },
                              expression: "showAnimation"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Show labels" },
                            model: {
                              value: _vm.showLabels,
                              callback: function($$v) {
                                _vm.showLabels = $$v
                              },
                              expression: "showLabels"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            staticClass: "full-w",
                            attrs: {
                              items: _vm.scoreLevels,
                              "item-value": "name",
                              "item-text": "label",
                              label: "Energy"
                            },
                            model: {
                              value:
                                _vm.checkupResult["ENERGY"].simplifiedValue,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.checkupResult["ENERGY"],
                                  "simplifiedValue",
                                  $$v
                                )
                              },
                              expression:
                                "checkupResult['ENERGY'].simplifiedValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.scoreLevels,
                              "item-value": "name",
                              "item-text": "label",
                              label: "Resilience"
                            },
                            model: {
                              value:
                                _vm.checkupResult["CD-RISC2"].simplifiedValue,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.checkupResult["CD-RISC2"],
                                  "simplifiedValue",
                                  $$v
                                )
                              },
                              expression:
                                "checkupResult['CD-RISC2'].simplifiedValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.scoreLevels,
                              "item-value": "name",
                              "item-text": "label",
                              label: "Anxiety"
                            },
                            model: {
                              value: _vm.checkupResult["GAD7"].simplifiedValue,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.checkupResult["GAD7"],
                                  "simplifiedValue",
                                  $$v
                                )
                              },
                              expression:
                                "checkupResult['GAD7'].simplifiedValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.scoreLevels,
                              "item-value": "name",
                              "item-text": "label",
                              label: "Depression"
                            },
                            model: {
                              value: _vm.checkupResult["PHQ9"].simplifiedValue,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.checkupResult["PHQ9"],
                                  "simplifiedValue",
                                  $$v
                                )
                              },
                              expression:
                                "checkupResult['PHQ9'].simplifiedValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.scoreLevels,
                              "item-value": "name",
                              "item-text": "label",
                              label: "Stress"
                            },
                            model: {
                              value:
                                _vm.checkupResult["STRESS"].simplifiedValue,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.checkupResult["STRESS"],
                                  "simplifiedValue",
                                  $$v
                                )
                              },
                              expression:
                                "checkupResult['STRESS'].simplifiedValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", md8: "", lg6: "", "offset-lg2": "" } },
            [
              _c(
                "v-container",
                { staticClass: "pa-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { sm12: "" } },
                            [
                              _c("checkup-results-quick-glance", {
                                attrs: {
                                  values: _vm.checkupResult,
                                  interpretations: _vm.interpretations,
                                  "show-drilldown": _vm.showDrilldown,
                                  "show-animation": _vm.showAnimation,
                                  "show-labels": _vm.showLabels
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }