






























































import Vue from 'vue';
import ElementStyledContent from '@/views/products/components/element-styled-content.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import { Milestone, Milestones } from '@/scripts/store/modules/my_starling/types';
import ElementPartGuide from '@/views/products/components/elements-parts/element-part-guide.vue';
import { IStyleContentGuide } from '@/scripts/store/modules/therapy/types';
import milestoneBus from '@/scripts/eventbus/milestoneEventBus';

export default Vue.extend({
  name: 'milestone-popup',
  components: {
    ElementPartGuide,
    PrimaryButton,
    ElementStyledContent,
  },
  data() {
    return {
      openDialog: false,
      badgeUrl: null as null | string,
    };
  },
  computed: {
    milestonesToDisplay(): Milestone[] {
      return this.$store.state.myStarling.milestones ? this.$store.state.myStarling.milestones.milestones.filter((m: Milestone) => m.display || m.forceDisplay) : [];
    },
    displayedMilestone(): Milestone | null {
      return this.milestonesToDisplay.length ? this.milestonesToDisplay[0] : null;
    },
    guide(): IStyleContentGuide {
      return {
        key: 'guide',
        icon: 'mini-bird',
        content: this.$t('app.profile.my_starling.milestones.popup.guide'),
        expanded: true,
      } as IStyleContentGuide;
    },
  },
  watch: {
    displayedMilestone(newVal: Milestone | null) {
      if (this.badgeUrl) {
        URL.revokeObjectURL(this.badgeUrl);
        this.badgeUrl = null;
      }

      if (newVal) {
        this.openDialog = true;
      } else {
        this.openDialog = false;
      }

      if (newVal && newVal.badge) {
        this.badgeUrl = URL.createObjectURL(new Blob([ newVal.badge ], { type: 'image/svg+xml' }));
      }
    },
    openDialog(newVal) {
      if (newVal) {
        const style = window.getComputedStyle(document.body);
        const colors = [
          style.getPropertyValue('--StarlingPrimary2'),
          style.getPropertyValue('--StarlingPrimary3'),
          style.getPropertyValue('--StarlingDarkGreen'),
          style.getPropertyValue('--StarlingSuccess'),
          style.getPropertyValue('--StarlingPaleRed'),
          style.getPropertyValue('--StarlingPaleYellow'),
          style.getPropertyValue('--StarlingWarning'),
        ];

        this.$confetti.start({
          defaultColors: colors,
        });
        this.$confetti.canvas.canvas.style.zIndex = '201';
      } else {
        this.$confetti.stop();
      }
    },
  },
  mounted() {
    milestoneBus.$on('refresh', this.refresh);
    milestoneBus.$on('open', this.open);
  },
  beforeDestroy() {
    milestoneBus.$off('refresh', this.refresh);
    milestoneBus.$off('open', this.open);
    if (this.badgeUrl) {
      URL.revokeObjectURL(this.badgeUrl);
      this.badgeUrl = null;
    }
  },
  methods: {
    refresh() {
      this.$store.dispatch('myStarling/getMilestones');
    },
    open(milestone: Milestone) {
      this.$store.dispatch('myStarling/getMilestones').then((res: Milestones) => {
        const found = res.milestones.find(m => m.id === milestone.id);
        if (found) {
          found.forceDisplay = true;
        }
      });
    },
    closePopup() {
      this.openDialog = false;
      setTimeout(() => {
        if (this.displayedMilestone) {
          if (this.displayedMilestone.display) {
            this.$store.dispatch('myStarling/readMilestone', this.displayedMilestone);
          }
          if (this.displayedMilestone.forceDisplay) {
            this.displayedMilestone.forceDisplay = false;
          }
        }
      }, 400);
    },
  },
});
