var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.notification
    ? _c(
        "div",
        [
          _c(
            "v-snackbar",
            {
              staticClass: "snackbar-element",
              attrs: {
                timeout: _vm.notification.timeout,
                color: _vm.notification.color,
                bottom: "",
                "auto-height": "",
                "multi-line": ""
              },
              model: {
                value: _vm.showSnackbar,
                callback: function($$v) {
                  _vm.showSnackbar = $$v
                },
                expression: "showSnackbar"
              }
            },
            [
              _c("span", { attrs: { id: "notif-content" } }, [
                _vm._v(_vm._s(_vm.notification.text))
              ]),
              _vm.notification.undoDelete
                ? _c(
                    "v-btn",
                    {
                      staticClass: "pl-1",
                      attrs: { flat: "" },
                      on: { click: _vm.notification.undoDelete }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("common.actions.undo")) +
                          "\n    "
                      )
                    ]
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "pl-2",
                      attrs: { icon: "", small: "", flat: "", dark: "" },
                      on: { click: _vm.removeNotification }
                    },
                    [_c("v-icon", [_vm._v("\n        close\n      ")])],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }