








































import Vue from 'vue';

export default Vue.extend({
  name: 'error-page',
  props: {
    errorData: {
      type: String,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
  },
  beforeMount() {
    if (!this.errorData) this.goHome();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.replace('/');
    },
  },
});
