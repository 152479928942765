var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            {
              staticClass: "text-center",
              attrs: {
                xs12: "",
                lg10: "",
                "offset-lg1": "",
                xl6: "",
                "offset-xl3": ""
              }
            },
            [
              _c(
                "v-timeline",
                { attrs: { dense: _vm.$vuetify.breakpoint.smAndDown } },
                _vm._l(_vm.items, function(item, i) {
                  return _c("timeline-item-" + item.type, {
                    key: i,
                    tag: "component",
                    attrs: { item: item }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }