<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 lg10 offset-lg1 xl6 offset-xl3 class="text-center">
        <v-timeline :dense="$vuetify.breakpoint.smAndDown">
          <component v-for="(item, i) in items" :key="i" v-bind:is="'timeline-item-'+item.type" :item="item"/>
        </v-timeline>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TimelineItemCheckup from '@/views/components/timeline/timeline-item-checkup';

export default {
  name: 'UserTesting3',
  components: { TimelineItemCheckup },
  data: () => ({
    items: [],
  }),
  mounted() {
    this.items = [];
    const maxItems = Math.floor(Math.random() * 15);
    for (let i = 0; i < maxItems; i++) {
      this.items.push(this.generateItem());
    }
    this.items = this.items.sort((a, b) => {
      return a.date <= b.date ? 1 : -1;
    });
  },
  methods: {
    generateItem() {
      const start = new Date(2020, 6, 1);
      const end = new Date();
      const randomDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
      const color = ((arr) => {
        return arr[Math.floor(Math.random() * arr.length)];
      })([ 'success', 'error', 'warning', 'primary' ]);
      return {
        type: 'checkup',
        date: randomDate,
        color,
        note: '',
        values: this.generateAssessmentData(),
      };
    },
    generateAssessmentData() {
      const generated = {};
      [ 'STRESS', 'GAD7', 'PHQ9', 'CD-RISC2', 'ENERGY' ].forEach(assessment => {
        generated[assessment] = { value: this.$t('app.assessments.scoreScale')[Math.floor(Math.random() * 7)].name };
      });
      return generated;
    },
  },
};
</script>

<style scoped>
.v-card.product-player-card {
  border-radius: 15px;
}
</style>
