<!-- Main navigation fixed to bottom of page -->

<template>
  <sm-improved-toolbar class="bottom-nav-mobile"
             fixed bottom
             height="56px"
             :scroll-off-screen="!isHome"
             :scroll-threshold="200">
    <v-layout fill-height align-center>
      <v-flex xs4 d-flex fill-height align-center justify-center>
        <v-btn flat class="fill-height StarlingDarkGrey--text ma-0 pa-0" @click="$root.$emit('showSideNav')">
          <v-badge right class="badge-small" :color="showMenuBadge?'':'transparent'">
            <template v-slot:badge>
              <span></span>
            </template>
            <v-icon class="ma-0">menu_open</v-icon>
          </v-badge>
          <span class="label">Menu</span>
        </v-btn>
      </v-flex>

      <v-flex xs4 d-flex fill-height align-center justify-center>
        <router-link :to="{ name: 'favorites' }" custom v-slot="{navigate, isActive}">
          <v-btn flat class="fill-height StarlingDarkGrey--text ma-0 pa-0" @click="navigate">
            <v-icon class="ma-0" :class="{'activated':isActive, 'StarlingPrimary1--text':isActive}">$vuetify.icons.starling_favorite</v-icon>
            <span class="label" :class="{'StarlingPrimary1--text':isActive}">{{ $t('app.navigation.favorites_button')}}</span>
          </v-btn>
        </router-link>
      </v-flex>

      <v-flex xs4 d-flex fill-height align-center justify-center>
        <router-link :to="{ name: 'profile' }" custom v-slot="{navigate, isActive}">
          <v-btn flat class="fill-height StarlingDarkGrey--text ma-0 pa-0" @click="navigate">
            <v-icon class="ma-0" :class="{'activated':isActive, 'StarlingPrimary1--text':isActive}">$vuetify.icons.starling_profile</v-icon>
            <span class="label" :class="{'StarlingPrimary1--text':isActive}">{{ $t('app.navigation.profile_button')}}</span>
          </v-btn>
        </router-link>
      </v-flex>
    </v-layout>
  </sm-improved-toolbar>
</template>

<script>
import SmImprovedToolbar from '@/views/components/sm-improved-toolbar.vue';

export default {
  name: 'bottom-nav-mobile',
  components: { SmImprovedToolbar },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    toolbarHeight() {
      return 48 + parseInt(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-bottom').replaceAll('px', ''));
    },
    isHome() {
      return this.$route.name === 'home';
    },
    commentStatuses() {
      return this.$store.getters['comments/getCommentsStatuses'];
    },
    showCommunityBadge() {
      return this.commentStatuses?.some(status => !status.read && !status.hidden);
    },
    visibleCallout() {
      return this.$store.getters['home/visibleCallout'];
    },
    showMenuBadge() {
      return this.visibleCallout || this.showCommunityBadge;
    },
  },
};
</script>

<style lang="scss">
.bottom-nav-mobile {
  .v-toolbar {
    .v-btn {
      .v-btn__content {
        flex-direction: column;

        .label {
          font-size: 12px;
          padding-top: 5px;
          max-width: 80px;
        }
      }
    }
  }
}
</style>
