<script>
import { VNavigationDrawer } from 'vuetify/lib';

/* @vue/component */
export default VNavigationDrawer.extend({
  name: 'sm-navigation-drawer',
  props: {
    touchlessOpen: Boolean,
  },
  methods: {
    swipeRight (e) {
      if (this.isActive && !this.right) return;
      if (!this.right && this.touchlessOpen) return;
      this.calculateTouchArea();

      if (Math.abs(e.touchendX - e.touchstartX) < 100) return;
      if (!this.right && e.touchstartX <= this.touchArea.left) this.isActive = true;
      else if (this.right && this.isActive) this.isActive = false;
    },
    swipeLeft (e) {
      if (this.isActive && this.right) return;
      if (this.right && this.touchlessOpen) return;
      this.calculateTouchArea();

      if (Math.abs(e.touchendX - e.touchstartX) < 100) return;
      if (this.right && e.touchstartX >= this.touchArea.right) this.isActive = true;
      else if (!this.right && this.isActive) this.isActive = false;
    },
  },
});
</script>
