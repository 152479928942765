<template>
<div class="full-page" :style="{'z-index': '1000', 'height': this.height? this.height+'px' : '100%'}">
  <div class="full-page-centered" :style="{'margin-left': $vuetify.breakpoint.mdAndUp ? ' -300px':''}">
    <div class="bounce">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'loading-view',
  data() {
    return {
      height: null,
    };
  },
  mounted() {
    let max = 0;
    setTimeout(() => {
      if (this.$el.parentElement) {
        Array.from(this.$el.parentElement.children).forEach(childNode => {
          const computed = getComputedStyle(childNode);
          const nodeHeight = parseInt(computed.height.replace('px', ''));
          const margins = parseFloat(computed.getPropertyValue('margin-top')) + parseFloat(computed.getPropertyValue('margin-bottom'));
          if (max < nodeHeight + margins) {
            max = nodeHeight + margins;
          }
        });
        this.height = max;
      }
    }, 150);
  },
};
</script>

<style>
.full-page {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: rgba(237, 237, 237, 0.6);
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.full-page .full-page-centered {
  position: relative;
}

.full-page .bounce {
  position: relative;
  margin: auto;
  width: 90px;
  height: 90px;
}

</style>
