import { render, staticRenderFns } from "./celebration-popup.vue?vue&type=template&id=24451436&"
import script from "./celebration-popup.vue?vue&type=script&lang=ts&"
export * from "./celebration-popup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VDialog,VFlex})


/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/starling-app-dev-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24451436')) {
      api.createRecord('24451436', component.options)
    } else {
      api.reload('24451436', component.options)
    }
    module.hot.accept("./celebration-popup.vue?vue&type=template&id=24451436&", function () {
      api.rerender('24451436', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/utilities/celebration-popup.vue"
export default component.exports