var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("side-nav"),
      _c(
        "v-content",
        [
          _c(
            "div",
            {
              staticClass: "content-container",
              class: {
                "with-bottom-nav": _vm.$vuetify.breakpoint.xsOnly
              }
            },
            [
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c("router-view", { attrs: { name: "topNavDesktop" } })
                : _vm._e(),
              _vm.$vuetify.breakpoint.smAndDown
                ? _c("router-view", { attrs: { name: "topNavMobile" } })
                : _vm._e(),
              _vm.isHome && _vm.$vuetify.breakpoint.mdAndUp
                ? _c("webinar-banner")
                : _vm._e(),
              _c(
                "v-container",
                {
                  staticClass: "main-content banner-container",
                  style: {
                    "margin-top": _vm.topNavHeight
                  },
                  attrs: { fluid: "", "pa-0": "" }
                },
                [
                  _vm.isHome && _vm.$vuetify.breakpoint.smAndDown
                    ? _c("webinar-banner")
                    : _vm._e(),
                  _c(
                    "v-layout",
                    {
                      staticClass: "content-area pb-5",
                      class: {
                        "px-3": _vm.$vuetify.breakpoint.smAndDown
                      },
                      attrs: { row: "", wrap: "", "justify-center": "" }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md8: "" } },
                        [
                          _c("router-view"),
                          _vm.maintenance
                            ? _c("maintenance-bar", { attrs: { rounded: "" } })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.loadingView ? _c("loading-view") : _vm._e()
            ],
            1
          ),
          _vm.$vuetify.breakpoint.smAndDown
            ? _c("bottom-nav-mobile")
            : _vm._e(),
          _c("notification-bar"),
          _c("celebration-popup"),
          _c("milestone-popup")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }