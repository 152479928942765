var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "sm-navigation-drawer",
        {
          staticClass: "side-nav elevation-3 bg-primary",
          style:
            "background-image: url('" +
            _vm.backgroundUrl +
            "'); background-repeat: no-repeat; background-size: cover;",
          attrs: {
            height: "100vh",
            fixed: "",
            permanent: _vm.$vuetify.breakpoint.mdAndUp,
            temporary: _vm.$vuetify.breakpoint.smAndDown,
            touchless: _vm.$vuetify.breakpoint.mdAndUp,
            "touchless-open": _vm.showBackButton
          },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "v-container",
            {
              staticClass: "menu-container",
              class: { mobile: _vm.$vuetify.breakpoint.smAndDown },
              attrs: { "fill-height": "", "px-2": "" }
            },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "justify-space-between": "",
                    column: "",
                    "fill-height": ""
                  }
                },
                [
                  _c("v-spacer", { staticClass: "top-spacer" }),
                  _vm._l(_vm.menuItems, function(item, index) {
                    return _c(
                      "v-flex",
                      {
                        key: index,
                        staticClass: "menu-item",
                        attrs: { shrink: "" }
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "d-inline",
                            attrs: { tag: "div", to: item.path },
                            nativeOn: {
                              click: function($event) {
                                return _vm.onClosePopover(
                                  _vm.visibleCallout &&
                                    _vm.visibleCallout.target === item.id
                                    ? "popover-" + _vm.visibleCallout.identifier
                                    : null
                                )
                              }
                            }
                          },
                          [
                            item.id === "community"
                              ? [
                                  _c(
                                    "v-badge",
                                    {
                                      staticClass: "badge-small mr-2",
                                      attrs: {
                                        right: "",
                                        color: _vm.showCommunityBadge
                                          ? ""
                                          : "transparent"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "badge",
                                            fn: function() {
                                              return [_c("span")]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            height: "25px",
                                            width: "25px"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.icon))]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      staticStyle: {
                                        height: "25px",
                                        width: "25px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.icon))]
                                  )
                                ],
                            _c(
                              "span",
                              { staticStyle: { "vertical-align": "top" } },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.label) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          2
                        ),
                        _vm.visibleCallout &&
                        _vm.visibleCallout.target === item.id
                          ? _c("badge-popover", {
                              key: _vm.visibleCallout
                                ? _vm.visibleCallout.identifier
                                : "",
                              ref: "popover-" + _vm.visibleCallout.identifier,
                              refInFor: true,
                              attrs: {
                                id: _vm.visibleCallout.identifier,
                                handle: _vm.visibleCallout.handle,
                                title: _vm.visibleCallout.title,
                                content: _vm.visibleCallout.content
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  _c("v-spacer"),
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-flex",
                        {
                          staticClass: "menu-item",
                          attrs: { shrink: "" },
                          on: { click: _vm.logout }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              staticStyle: { height: "25px", width: "25px" }
                            },
                            [_vm._v("$vuetify.icons.starling_logout")]
                          ),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("common.actions.logout")))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.partner && _vm.partner.type === "COLABELLED"
                    ? _c(
                        "v-flex",
                        {
                          staticClass: "powered-by",
                          attrs: { shrink: "", "text-center": "" }
                        },
                        [
                          _c("p", { staticClass: "starling-italic-text" }, [
                            _vm._v(
                              _vm._s(_vm.$t("common.partnership.powered_by"))
                            )
                          ]),
                          _c("starling-logo", {
                            staticStyle: { "max-width": "85px" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }