










































import Vue from 'vue';

export default Vue.extend({
  name: 'appstore-bar',
  data() {
    return {
      dismissed: localStorage.getItem(`${this.$store.getters.user.id}_appstore`) != null,
    };
  },
  computed: {
    isNativePlatform(): boolean {
      return this.$store.getters.isNativePlatform;
    },
    showBar(): boolean {
      return !this.dismissed && !this.isNativePlatform;
    },
    mobileOS(): string | undefined {
      if (this.$vuetify.breakpoint.mdAndUp) return undefined;
      const userAgent = navigator.userAgent;
      if (/android/i.test(userAgent)) return 'android';
      if ((/iPad|iPhone|iPod/.test(userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) return 'ios';
      return undefined;
    },
  },
  mounted() {
    this.$emit('update', this.showBar);
  },
  methods: {
    onBannerClose() {
      localStorage.setItem(`${this.$store.getters.user.id}_appstore`, '');
      this.dismissed = true;
      this.$emit('update', false);
    },
  },
});
