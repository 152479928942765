<template>
<div v-if="notification">
  <v-snackbar
          class="snackbar-element"
          v-model="showSnackbar"
          :timeout="notification.timeout"
          :color="notification.color"
          bottom
          auto-height
          multi-line>
    <span id="notif-content">{{ notification.text }}</span>
    <v-btn v-if="notification.undoDelete"
           class="pl-1"
           flat
           @click="notification.undoDelete">
      {{ $t('common.actions.undo' )}}
    </v-btn>

    <v-btn v-else icon small flat dark class="pl-2"
           @click="removeNotification">
      <v-icon>
        close
      </v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
export default {
  name: 'notificationBar',
  data() {
    return {
      showSnackbar: false,
    };
  },
  computed: {
    notification() {
      return this.$store.getters.notification;
    },
  },
  watch: {
    notification(notification) {
      this.showSnackbar = !!notification;
      this.$log.debug('Show new notification ?', notification, this.showSnackbar);
    },
  },
  methods: {
    removeNotification() {
      this.$store.commit('setUndoDelete', false);
      this.$store.commit('removeNotification');
    },
  },
};
</script>

<style>
.snackbar-element .v-snack__wrapper {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
</style>
