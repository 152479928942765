<template>
  <div class='post-it' contenteditable @input="onInput" ref="postit">
  </div>
</template>

<script>
export default {
  name: 'sticky-note',
  props: {
    value: String,
  },
  watch: {
    value: {
      handler(newValue) {
        this.$refs.postit = newValue;
      },
    },
  },
  mounted() {
    this.$refs.postit = this.value;
  },
  methods: {
    onInput(evt) {
      this.$emit('input', evt.target.innerText);
    },
  },
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);

* {
  box-sizing: border-box;
}

body {
  background: #444;
}

.post-it {
  position: relative;
  margin: 30px auto;
  border-radius: 0 0 0 30px/45px;
  box-shadow: inset 0 -40px 40px rgba(0, 0, 0, 0.2), inset 0 25px 10px rgba(0, 0, 0, 0.2), 0 5px 6px 5px rgba(0, 0, 0, 0.2);
  background: #ffa;
  padding: 20px;
  width: 300px;
  height: 288px;
  overflow: hidden;
  line-height: 1.7em;
  color: #130d6b;
  font-family: 'Permanent Marker', cursive;
  font-size: 19px;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

.post-it li {
  cursor: pointer;
}

.post-it::before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: skewX(25deg);
  z-index: 2;
  box-shadow: 3px -2px 10px rgba(0, 0, 0, 0.2), inset 15px -15px 15px rgba(0, 0, 0, 0.3);
  background: #ffa;
  width: 20px;
  height: 25px;
  content: "";
}

.post-it::after {
  display: block;
  position: absolute;
  bottom: 0;
  left: 10%;
  border-top: 3px solid #130d6b;
  border-radius: 50%;
  width: 75%;
  height: 20px;
  content: "";
}

.done {
  text-decoration: line-through;
}

h1 {
  position: relative;
  font-size: 25px;
}
</style>
