<template>
  <div class="text-xs-center my-4" v-if="maxPages && (maxPages > 1 || properties.showMore)">
    <ul class="v-pagination v-pagination--circle theme--light">
      <li v-for="(page, index) in pageNumbers" :key="index">
        <button v-if="page !== maxPages || !allCompleted"
          class="v-pagination__item"
          @click="goToPage(page)"
          :disabled="disabledPage(page)"
          :class="{
            'v-pagination__navigation--disabled': disabledPage(page),
            'page-completed': page < properties.currentPage || (page === properties.currentPage && allCompleted),
            'page-current': page === properties.currentPage,
          }"
        >
          {{ index === 0 && page !== 1 ? '...' : page }}
        </button>
        <button v-else
          class="v-pagination__item page-completed"
          disabled
        >
          <v-icon color="white">mdi-check</v-icon>
        </button>
      </li>
      <li v-if="properties.loading">
        <v-progress-circular color="primary" indeterminate style="margin:.3rem;"></v-progress-circular>
      </li>
      <li v-if="!properties.loading && (properties.showMore || pageNumbers[pageNumbers.length - 1] !== maxPages)">
        <button
          class="v-pagination__item v-pagination__navigation--disabled"
          disabled
        >...</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'customPagination',
  props: {
    properties: {
      currentPage: {
        type: Number,
        required: false,
      },
      totalPages: {
        type: Number,
        required: false,
      },
      totalVisible: {
        type: Number,
        required: false,
      },
      currentCompleted: {
        type: Boolean,
        required: false,
      },
      showMore: {
        type: Boolean,
        required: false,
      },
      loading: {
        type: Boolean,
        required: false,
      },
      required: false,
      default: {},
    },
  },
  data() {
    return {
    };
  },
  computed: {
    maxPages() {
      return this.properties.totalPages || this.properties.currentPage;
    },
    maxVisible() {
      return this.properties.totalVisible || (this.$vuetify.breakpoint.xsOnly ? 5 : this.$vuetify.breakpoint.smOnly ? 7 : 10);
    },
    pageNumbers() {
      const pages = Array.from({ length: this.maxPages }, (_, i) => i + 1);
      const middle = Math.ceil(this.maxVisible / 2);
      const remainder = this.maxVisible % 2 === 0 ? 0 : 1;

      if (this.maxVisible >= this.maxPages) {
        return pages;
      }

      let start, end;
      if (this.properties.currentPage - middle < 1) {
        start = 0;
        end = this.maxVisible;
      } else if (this.properties.currentPage + middle > this.maxPages) {
        start = this.maxPages - this.maxVisible;
        end = this.maxPages;
      } else {
        start = this.properties.currentPage - middle;
        end = this.properties.currentPage + middle - remainder;
      }

      return pages.slice(start, end);
    },
    allCompleted() {
      return !this.properties.showMore && this.properties.currentCompleted && this.properties.currentPage === this.maxPages;
    },
  },
  methods: {
    disabledPage(page) {
      return this.properties.loading || (page === this.properties.currentPage + 1 && !this.properties.currentCompleted) ||
        page > this.properties.currentPage + 1 || page < this.properties.currentPage - 1;
    },
    goToPage(page) {
      if (page > this.properties.currentPage) {
        this.$emit('next');
      }
      if (page < this.properties.currentPage) {
        this.$emit('previous');
      }
    },
  },
};
</script>

<style scoped>
.page-completed {
  background-color: var(--StarlingSuccess) !important;
  border-color: var(--StarlingSuccess) !important;
}
.page-current {
  border: 3px solid;
  border-color: var(--StarlingSuccess);
}
</style>
