var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-content", [
    _c(
      "div",
      {
        staticClass: "content-container background",
        class: {
          "with-bottom-nav": _vm.$vuetify.breakpoint.xsOnly,
          "background-non-desktop": _vm.$vuetify.breakpoint.mdAndDown,
          "background-mobile": _vm.$vuetify.breakpoint.xsOnly,
          "background-xl": _vm.$vuetify.breakpoint.xlOnly
        }
      },
      [
        _c("div", {
          staticClass: "side-menu",
          class: {
            "desktop elevation-3": _vm.$vuetify.breakpoint.mdAndUp
          }
        }),
        _c("div", {
          staticClass: "header",
          class: {
            mobile: _vm.$vuetify.breakpoint.xsOnly,
            "desktop elevation-5": _vm.$vuetify.breakpoint.smAndUp
          }
        }),
        _c(
          "v-container",
          { attrs: { fluid: "", "pa-0": "" } },
          [
            _c(
              "v-layout",
              {
                class: {
                  "pa-0": _vm.$vuetify.breakpoint.xsOnly,
                  "pr-2 py-2": _vm.$vuetify.breakpoint.smOnly,
                  "pr-3 py-3": _vm.$vuetify.breakpoint.mdOnly,
                  "pr-4 py-4": _vm.$vuetify.breakpoint.lgOnly,
                  "pr-5 py-5": _vm.$vuetify.breakpoint.xlOnly
                }
              },
              [
                _vm.$vuetify.breakpoint.smAndUp
                  ? _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "starling-lightgrey",
                              flat: "",
                              fab: "",
                              large: "",
                              disabled: ""
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-flex",
                  {
                    attrs: {
                      xs12: "",
                      sm10: "",
                      lg8: "",
                      "align-center": "",
                      "justify-center": ""
                    }
                  },
                  [
                    _c(
                      "v-card",
                      {
                        class: {
                          "product-player-card": _vm.$vuetify.breakpoint.smAndUp
                        },
                        attrs: {
                          elevation: _vm.$vuetify.breakpoint.xsOnly ? 0 : 3,
                          flat: ""
                        }
                      },
                      [
                        _vm.therapyGroup
                          ? _c("element-view", {
                              key: _vm.therapyGroup.sessionUen,
                              attrs: {
                                "therapy-group": _vm.therapyGroup,
                                isPreview: ""
                              }
                            })
                          : _c("v-card-loader")
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }