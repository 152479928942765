var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.maxPages && (_vm.maxPages > 1 || _vm.properties.showMore)
    ? _c("div", { staticClass: "text-xs-center my-4" }, [
        _c(
          "ul",
          { staticClass: "v-pagination v-pagination--circle theme--light" },
          [
            _vm._l(_vm.pageNumbers, function(page, index) {
              return _c("li", { key: index }, [
                page !== _vm.maxPages || !_vm.allCompleted
                  ? _c(
                      "button",
                      {
                        staticClass: "v-pagination__item",
                        class: {
                          "v-pagination__navigation--disabled": _vm.disabledPage(
                            page
                          ),
                          "page-completed":
                            page < _vm.properties.currentPage ||
                            (page === _vm.properties.currentPage &&
                              _vm.allCompleted),
                          "page-current": page === _vm.properties.currentPage
                        },
                        attrs: { disabled: _vm.disabledPage(page) },
                        on: {
                          click: function($event) {
                            return _vm.goToPage(page)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(index === 0 && page !== 1 ? "..." : page) +
                            "\n      "
                        )
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "v-pagination__item page-completed",
                        attrs: { disabled: "" }
                      },
                      [
                        _c("v-icon", { attrs: { color: "white" } }, [
                          _vm._v("mdi-check")
                        ])
                      ],
                      1
                    )
              ])
            }),
            _vm.properties.loading
              ? _c(
                  "li",
                  [
                    _c("v-progress-circular", {
                      staticStyle: { margin: ".3rem" },
                      attrs: { color: "primary", indeterminate: "" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.properties.loading &&
            (_vm.properties.showMore ||
              _vm.pageNumbers[_vm.pageNumbers.length - 1] !== _vm.maxPages)
              ? _c("li", [
                  _c(
                    "button",
                    {
                      staticClass:
                        "v-pagination__item v-pagination__navigation--disabled",
                      attrs: { disabled: "" }
                    },
                    [_vm._v("...")]
                  )
                ])
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }