<template>
  <transition name="slide-right" mode="out-in">
    <v-layout row wrap :key="currentPage">
      <v-flex xs12>
        <slot></slot>
      </v-flex>
      <custom-pagination
        :key="currentPage"
        :properties="pagination"
        @next="next"
        @previous="previous"
      ></custom-pagination>
    </v-layout>
  </transition>
</template>

<script>
import cssVars from 'css-vars-ponyfill';
import customPagination from '@/views/components/utilities/custom-pagination';

export default {
  name: 'custom-carousel',
  components: { customPagination },
  props: {
    pagination: {
      type: Object,
      required: false,
    },
  },
  computed: {
    currentPage() {
      return this.pagination && this.pagination.currentPage ? this.pagination.currentPage : 0;
    },
  },
  mounted() {
    cssVars({});
  },
  methods: {
    previous() {
      this.$emit('previous');
    },
    next() {
      this.$emit('next');
    },
  },
};
</script>

<style>
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-right-leave-active {
  opacity: 0;
  transform: translate(4em, 0);
}

.slide-right-enter {
  opacity: 0;
  transform: translate(-4em, 0);
}
</style>
