var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sm-improved-toolbar",
    {
      staticClass: "bottom-nav-mobile",
      attrs: {
        fixed: "",
        bottom: "",
        height: "56px",
        "scroll-off-screen": !_vm.isHome,
        "scroll-threshold": 200
      }
    },
    [
      _c(
        "v-layout",
        { attrs: { "fill-height": "", "align-center": "" } },
        [
          _c(
            "v-flex",
            {
              attrs: {
                xs4: "",
                "d-flex": "",
                "fill-height": "",
                "align-center": "",
                "justify-center": ""
              }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "fill-height StarlingDarkGrey--text ma-0 pa-0",
                  attrs: { flat: "" },
                  on: {
                    click: function($event) {
                      return _vm.$root.$emit("showSideNav")
                    }
                  }
                },
                [
                  _c(
                    "v-badge",
                    {
                      staticClass: "badge-small",
                      attrs: {
                        right: "",
                        color: _vm.showMenuBadge ? "" : "transparent"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "badge",
                          fn: function() {
                            return [_c("span")]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("v-icon", { staticClass: "ma-0" }, [
                        _vm._v("menu_open")
                      ])
                    ],
                    1
                  ),
                  _c("span", { staticClass: "label" }, [_vm._v("Menu")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            {
              attrs: {
                xs4: "",
                "d-flex": "",
                "fill-height": "",
                "align-center": "",
                "justify-center": ""
              }
            },
            [
              _c("router-link", {
                attrs: { to: { name: "favorites" }, custom: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var navigate = ref.navigate
                      var isActive = ref.isActive
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass:
                              "fill-height StarlingDarkGrey--text ma-0 pa-0",
                            attrs: { flat: "" },
                            on: { click: navigate }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "ma-0",
                                class: {
                                  activated: isActive,
                                  "StarlingPrimary1--text": isActive
                                }
                              },
                              [_vm._v("$vuetify.icons.starling_favorite")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "label",
                                class: { "StarlingPrimary1--text": isActive }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("app.navigation.favorites_button")
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-flex",
            {
              attrs: {
                xs4: "",
                "d-flex": "",
                "fill-height": "",
                "align-center": "",
                "justify-center": ""
              }
            },
            [
              _c("router-link", {
                attrs: { to: { name: "profile" }, custom: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var navigate = ref.navigate
                      var isActive = ref.isActive
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass:
                              "fill-height StarlingDarkGrey--text ma-0 pa-0",
                            attrs: { flat: "" },
                            on: { click: navigate }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "ma-0",
                                class: {
                                  activated: isActive,
                                  "StarlingPrimary1--text": isActive
                                }
                              },
                              [_vm._v("$vuetify.icons.starling_profile")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "label",
                                class: { "StarlingPrimary1--text": isActive }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("app.navigation.profile_button")
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }