var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        transition: "scale-transition",
        origin: "center center",
        "max-width": _vm.$vuetify.breakpoint.xsOnly ? "98%" : "800px",
        "content-class": "element-celebration-opened radius-15",
        light: "",
        persistent: ""
      },
      model: {
        value: _vm.openDialog,
        callback: function($$v) {
          _vm.openDialog = $$v
        },
        expression: "openDialog"
      }
    },
    [
      _vm.openDialog && _vm.displayedMilestone
        ? _c(
            "v-card",
            {
              ref: "milestone-dialog",
              class: {
                "pa-3": _vm.$vuetify.breakpoint.lgAndUp,
                "pa-2": _vm.$vuetify.breakpoint.mdOnly,
                "pa-2": _vm.$vuetify.breakpoint.smAndDown
              }
            },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", [
                    _c("h1", { staticClass: "starling-h1" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.profile.my_starling.milestones.popup.title"
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { staticClass: "my-2", attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs10: "", sm8: "", md6: "", lg5: "" } },
                    [
                      _vm.badgeUrl
                        ? _c("v-img", {
                            attrs: {
                              contain: "",
                              "aspect-ratio": 1,
                              src: _vm.badgeUrl,
                              alt: _vm.displayedMilestone.altText
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.displayedMilestone.title
                ? _c(
                    "v-layout",
                    { staticClass: "my-2", attrs: { row: "", wrap: "" } },
                    [
                      _c("v-flex", [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "starling-h2 text-center StarlingAlmostBlack--text font-weight-bold"
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.displayedMilestone.title)
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.displayedMilestone.subtitle
                ? _c(
                    "v-layout",
                    { staticClass: "my-2", attrs: { row: "", wrap: "" } },
                    [
                      _c("v-flex", [
                        _c(
                          "h5",
                          {
                            staticClass:
                              "starling-h5 text-center font-weight-regular"
                          },
                          [_vm._v(_vm._s(_vm.displayedMilestone.subtitle))]
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-layout",
                { staticClass: "my-2", attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    [_c("element-part-guide", { attrs: { part: _vm.guide } })],
                    1
                  )
                ],
                1
              ),
              _vm.displayedMilestone.content
                ? _c(
                    "v-layout",
                    { staticClass: "my-2", attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("element-styled-content", {
                            attrs: { content: _vm.displayedMilestone.content }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-layout",
                { staticClass: "mb-2", attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "text-center": "" } },
                    [
                      _c(
                        "primary-button",
                        {
                          attrs: { large: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.closePopup($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("common.actions.close")) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }