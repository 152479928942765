var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-timeline-item",
    {
      attrs: {
        color: _vm.item.color,
        icon: _vm.item.icon,
        small: _vm.$vuetify.breakpoint.mdAndDown,
        right: "",
        "fill-dot": ""
      },
      scopedSlots: _vm._u(
        [
          _vm.$vuetify.breakpoint.lgAndUp
            ? {
                key: "opposite",
                fn: function() {
                  return [
                    _c("sticky-note", {
                      model: {
                        value: _vm.note,
                        callback: function($$v) {
                          _vm.note = $$v
                        },
                        expression: "note"
                      }
                    })
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticStyle: { width: "100%", "text-align": "left" } },
              [
                _c(
                  "span",
                  { class: "font-weight-bold " + _vm.item.color + "--text" },
                  [_vm._v(_vm._s(_vm._f("moment")(_vm.item.date, "LL")))]
                ),
                _vm.$vuetify.breakpoint.mdAndDown
                  ? _c(
                      "v-icon",
                      {
                        staticStyle: { cursor: "pointer", float: "right" },
                        on: { click: _vm.openNote }
                      },
                      [_vm._v("mdi-note-outline")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "text-center",
                      attrs: { xs12: "", lg10: "" }
                    },
                    [
                      _c("checkup-results-radarchart", {
                        attrs: {
                          values: _vm.item.values,
                          width: "100%",
                          "show-drilldown": false
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.$vuetify.breakpoint.mdAndDown
        ? _c(
            "v-dialog",
            {
              staticStyle: { margin: "0", "box-shadow": "none" },
              model: {
                value: _vm.openedNote,
                callback: function($$v) {
                  _vm.openedNote = $$v
                },
                expression: "openedNote"
              }
            },
            [
              _c("sticky-note", {
                model: {
                  value: _vm.note,
                  callback: function($$v) {
                    _vm.note = $$v
                  },
                  expression: "note"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }