<template>
  <v-container fluid class="pa-0">
    <v-layout row wrap>
      <v-flex xs12 md4 lg2>
        <v-container fluid>

          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox v-model="showDrilldown" label="Show drilldowns"/>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox v-model="showAnimation" label="Show animation"/>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox v-model="showLabels" label="Show labels"/>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12>
              <v-select class="full-w" :items="scoreLevels" item-value="name" item-text="label" v-model="checkupResult['ENERGY'].simplifiedValue" label="Energy"></v-select>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <v-select :items="scoreLevels" item-value="name" item-text="label" v-model="checkupResult['CD-RISC2'].simplifiedValue" label="Resilience"></v-select>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <v-select :items="scoreLevels" item-value="name" item-text="label" v-model="checkupResult['GAD7'].simplifiedValue" label="Anxiety"></v-select>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <v-select :items="scoreLevels" item-value="name" item-text="label" v-model="checkupResult['PHQ9'].simplifiedValue" label="Depression"></v-select>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <v-select :items="scoreLevels" item-value="name" item-text="label" v-model="checkupResult['STRESS'].simplifiedValue" label="Stress"></v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>

      <v-flex xs12 md8 lg6 offset-lg2>
        <v-container fluid class="pa-0">
          <v-card class="pa-0">
            <v-layout row wrap>
              <v-flex sm12>
                <checkup-results-quick-glance :values="checkupResult" :interpretations="interpretations" :show-drilldown="showDrilldown" :show-animation="showAnimation" :show-labels="showLabels"/>
              </v-flex>
            </v-layout>
          </v-card>
        </v-container>
      </v-flex>

    </v-layout>
  </v-container>
</template>

<script>
import CheckupResultsQuickGlance from '@/views/home/components/checkup-results-quick-glance';

export default {
  name: 'Testing',
  components: {
    CheckupResultsQuickGlance,
  },
  data() {
    return {
      showDrilldown: false,
      showAnimation: true,
      showLabels: true,
      checkupResult: {
        ENERGY: {
          simplifiedValue: 'HI',
        },
        'CD-RISC2': {
          simplifiedValue: 'HI',
        },
        GAD7: {
          simplifiedValue: 'VL',
        },
        PHQ9: {
          simplifiedValue: 'VL',
        },
        STRESS: {
          simplifiedValue: 'NOT_EVALUATED',
        },
      },
      interpretations: {
        ENERGY: {
          matched: true,
          content: [ {
            key: 'h1',
            content: 'This is a test H1',
          } ],
        },
        'CD-RISC2': {
          matched: true,
          content: [ {
            key: 'h1',
            content: 'This is a test H1',
          } ],
        },
        GAD7: {
          matched: true,
          content: [ {
            key: 'h1',
            content: 'This is a test H1',
          } ],
        },
        PHQ9: {
          matched: true,
          content: [ {
            key: 'h1',
            content: 'This is a test H1',
          } ],
        },
        STRESS: {
          matched: false,
          content: [ {
            key: 'h1',
            content: 'This is a test H1',
          } ],
        },
      },
    };
  },
  computed: {
    scoreLevels() {
      return this.$t('app.assessments.simplifiedScoreScale');
    },
  },
};
</script>

<style scoped>
.v-input__control {
  width: 100%;
}
</style>
