var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showBanner && !_vm.loading
        ? _c(
            "v-alert",
            {
              staticClass: "banner-bar ma-0 pa-0 no-select",
              class: _vm.$vuetify.breakpoint.smAndDown ? "mobile" : "desktop",
              attrs: { value: true, color: "StarlingSupplementary--bg" }
            },
            [
              _c(
                "v-layout",
                {
                  staticClass: "banner-content",
                  class: { "pl-3 pr-1": _vm.$vuetify.breakpoint.smAndDown },
                  attrs: { "fill-height": "" }
                },
                [
                  _vm.showErrorMessage
                    ? _c(
                        "v-flex",
                        { attrs: { grow: "", md8: "", "offset-md2": "" } },
                        [
                          _c("p", {
                            staticClass:
                              "starling-text StarlingPrimary1--text text-center",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(
                                  "app.products.element.webinar_registration.error_message"
                                )
                              )
                            }
                          })
                        ]
                      )
                    : _c(
                        "v-flex",
                        { attrs: { grow: "", md8: "", "offset-md2": "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "", "d-flex": "" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      class: _vm.$vuetify.breakpoint.smAndDown
                                        ? "banner-tile-mobile"
                                        : "banner-tile"
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _vm.imageUrl
                                                ? _c("v-img", {
                                                    class: _vm.$vuetify
                                                      .breakpoint.smAndDown
                                                      ? "module-cover-image-mobile"
                                                      : "module-cover-image",
                                                    attrs: {
                                                      src: _vm.imageUrl,
                                                      "aspect-ratio": "1.78"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              [
                                _vm.isToday
                                  ? _c(
                                      "v-flex",
                                      { staticClass: "flex-aligned" },
                                      [
                                        _c("div", [
                                          _c(
                                            "h2",
                                            {
                                              staticClass:
                                                "starling-h2 webinar-topic ml-2"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "app.home.webinar_banner.title_today"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass: "starling-body ml-2"
                                            },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.webinarTopicTrimmed
                                                  )
                                                )
                                              ]),
                                              _c("br"),
                                              _c("i", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatTime(
                                                      _vm.bannerWebinar
                                                        .occurrunceTodayStartTime
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ]),
                                        _c(
                                          "primary-button",
                                          {
                                            staticClass: "ml-2 mt-auto",
                                            staticStyle: { width: "150px" },
                                            attrs: { disabled: "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "app.home.webinar_banner.join_button"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm.isLive
                                  ? _c(
                                      "v-flex",
                                      { staticClass: "flex-aligned" },
                                      [
                                        _c("div", [
                                          _c(
                                            "h2",
                                            {
                                              staticClass:
                                                "starling-h2 webinar-topic ml-2"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "app.home.webinar_banner.title_live"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass: "starling-body ml-2"
                                            },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.webinarTopicTrimmed
                                                  )
                                                )
                                              ]),
                                              _c("br"),
                                              _c("i", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatTime(
                                                      _vm.bannerWebinar
                                                        .occurrunceTodayStartTime
                                                    )
                                                  )
                                                )
                                              ]),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "app.home.webinar_banner.title_to"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("i", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatTime(
                                                      _vm.bannerWebinar
                                                        .occurrunceTodayEndTime
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ]),
                                        _c(
                                          "primary-button",
                                          {
                                            staticClass: "ml-2 mt-auto",
                                            staticStyle: { width: "150px" },
                                            on: { click: _vm.handleJoin }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "app.home.webinar_banner.join_button"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-flex",
                                      { staticClass: "flex-aligned" },
                                      [
                                        _c("div", [
                                          _c(
                                            "h3",
                                            {
                                              staticClass:
                                                "starling-h3 webinar-topic ml-2"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.webinarTopicTrimmed)
                                              )
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass: "starling-body ml-2"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatDate(
                                                    _vm.bannerWebinar
                                                      .webinarStartTime
                                                  )
                                                ) +
                                                  " - " +
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      _vm.bannerWebinar
                                                        .webinarEndTime
                                                    )
                                                  )
                                              )
                                            ]
                                          )
                                        ]),
                                        !_vm.bannerWebinar.isRegistered
                                          ? _c(
                                              "primary-button",
                                              {
                                                staticClass: "ml-2 mt-auto",
                                                staticStyle: { width: "150px" },
                                                attrs: {
                                                  disabled:
                                                    _vm.bannerWebinar
                                                      .isRegistered
                                                },
                                                on: {
                                                  click:
                                                    _vm.handleRegistrationClick
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "app.home.webinar_banner.register_button"
                                                      )
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                              ]
                            ],
                            2
                          )
                        ],
                        1
                      ),
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        shrink: "",
                        md2: "",
                        "pl-3": _vm.$vuetify.breakpoint.mdAndUp
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.onBannerClose }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "StarlingDarkblue" } },
                            [_vm._v("close")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "390px",
            attach: "body",
            "content-class": "radius-15"
          },
          model: {
            value: _vm.showRegisterMessage,
            callback: function($$v) {
              _vm.showRegisterMessage = $$v
            },
            expression: "showRegisterMessage"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3 pt-2", attrs: { flat: "" } },
            [
              _c("v-card-title", { staticClass: "pa-0" }, [
                _c("h2", { staticClass: "starling-h2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("app.home.webinar_banner.confirmation.title"))
                  )
                ])
              ]),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("p", { staticClass: "starling-body" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "app.home.webinar_banner.confirmation.description"
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pa-0 pt-3" },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "justify-center": "",
                            "align-center": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "text-center": "" } },
                            [
                              _c(
                                "primary-button",
                                {
                                  attrs: { large: "" },
                                  on: { click: _vm.onConfirmContinue }
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "app.home.webinar_banner.confirmation.continue_button"
                                        )
                                      ) +
                                      "\n                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }