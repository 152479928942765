var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.celebrationPopup
    ? _c(
        "v-dialog",
        {
          attrs: {
            value: true,
            "max-width": _vm.$vuetify.breakpoint.xsOnly ? "98%" : "800px",
            "content-class": "element-celebration-opened radius-15",
            light: "",
            persistent: ""
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c("element-styled-content", {
                attrs: { content: _vm.celebrationPopup.styleContent },
                scopedSlots: _vm._u(
                  [
                    _vm.actionLabel
                      ? {
                          key: "action",
                          fn: function() {
                            return [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    "text-center": "",
                                    "mt-3": ""
                                  }
                                },
                                [
                                  _c(
                                    "primary-button",
                                    {
                                      attrs: { large: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.closePopup($event)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.actionLabel) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }