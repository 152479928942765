var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBar
    ? _c(
        "v-alert",
        {
          staticClass: "appstore-bar ma-0 pa-0 no-select",
          class: _vm.$vuetify.breakpoint.smAndDown ? "mobile" : "desktop",
          attrs: { value: true, color: "StarlingGanonBlue--bg" }
        },
        [
          _c(
            "v-layout",
            {
              class: { "pl-3 pr-1": _vm.$vuetify.breakpoint.smAndDown },
              attrs: { "align-center": "", "fill-height": "" }
            },
            [
              _c(
                "v-flex",
                { attrs: { grow: "", md8: "", "offset-md2": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "", "d-flex": "" } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "pr-1",
                              attrs: { color: "StarlingDarkblue" }
                            },
                            [_vm._v("smartphone")]
                          )
                        ],
                        1
                      ),
                      _vm.mobileOS
                        ? [
                            _c("v-flex", { attrs: { grow: "" } }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "starling-text StarlingDarkblue--text"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "app.home.subheader.app_store.mobile"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c(
                              "v-flex",
                              { attrs: { shrink: "", "d-flex": "" } },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "app-link radius-full white StarlingPrimary1--text font-weight-550 ellipsis ml-1",
                                    attrs: {
                                      href: _vm.$t(
                                        "app.home.subheader.app_store." +
                                          _vm.mobileOS +
                                          "_link"
                                      ),
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "app.home.subheader.app_store.button"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        : [
                            _c("v-flex", { attrs: { grow: "" } }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "starling-text StarlingDarkblue--text"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "app.home.subheader.app_store.desktop"
                                      )
                                    )
                                  )
                                ]
                              )
                            ])
                          ]
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    shrink: "",
                    md2: "",
                    "pl-3": _vm.$vuetify.breakpoint.mdAndUp
                  }
                },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.onBannerClose } },
                    [
                      _c("v-icon", { attrs: { color: "StarlingDarkblue" } }, [
                        _vm._v("close")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }