<template>
<v-content>
  <div class="content-container background"
       :class="{
          'with-bottom-nav':$vuetify.breakpoint.xsOnly,
          'background-non-desktop':$vuetify.breakpoint.mdAndDown,
          'background-mobile':$vuetify.breakpoint.xsOnly,
          'background-xl':$vuetify.breakpoint.xlOnly,
        }">

    <div class="side-menu"
        :class="{
          'desktop elevation-3':$vuetify.breakpoint.mdAndUp,
        }">
    </div>

    <div class="header"
        :class="{
          'mobile':$vuetify.breakpoint.xsOnly,
          'desktop elevation-5':$vuetify.breakpoint.smAndUp,
        }">
    </div>

    <v-container fluid pa-0>
      <v-layout :class="{'pa-0':$vuetify.breakpoint.xsOnly,
                                'pr-2 py-2':$vuetify.breakpoint.smOnly,
                                'pr-3 py-3':$vuetify.breakpoint.mdOnly,
                                'pr-4 py-4':$vuetify.breakpoint.lgOnly,
                                'pr-5 py-5':$vuetify.breakpoint.xlOnly}">

        <v-flex shrink v-if="$vuetify.breakpoint.smAndUp">
          <v-btn color="starling-lightgrey" flat fab large disabled>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-flex>

        <v-flex xs12 sm10 lg8 align-center justify-center>
          <v-card :elevation="$vuetify.breakpoint.xsOnly? 0:3"
                flat
                :class="{'product-player-card':$vuetify.breakpoint.smAndUp}">
            <element-view v-if="therapyGroup"
              :therapy-group="therapyGroup"
              :key="therapyGroup.sessionUen"
              isPreview
            ></element-view>
            <v-card-loader v-else></v-card-loader>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</v-content>
</template>

<script>
import elementView from '@/views/products/components/element-view.vue';
import VCardLoader from '@/views/components/loader/v-card-loader.vue';

export default {
  name: 'Preview',
  components: {
    VCardLoader,
    elementView,
  },
  data() {
    return {
      therapyGroup: null,
    };
  },
  created() {
    window.addEventListener('message', this.receiveTherapyContent);
    window.parent.postMessage({ type: 'therapyContent' }, '*');
    this.$log.debug('MessageEvent listener created, notifying parent');
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveTherapyContent);
  },
  methods: {
    receiveTherapyContent(event) {
      this.$log.debug('MessageEvent received', event);
      if (event.data && event.data.type && event.data.type === 'therapyContent') {
        this.therapyGroup = event.data.content;
      }
    },
  },
};
</script>

<style scoped>
.content-container.background {
  background-color: #EAF6FB;
  background-image: url('/svg/bg-app-content.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: local;
  background-position: 0 -50px;
  overscroll-behavior: auto;
}
.content-container.background-non-desktop {
  background-position: -280px -210px;
}
.content-container.background-mobile {
  background-size: 320%;
}
.content-container.background-xl {
  background-size: 100%;
}
.v-card.product-player-card {
  border-radius: 15px;
}
.side-menu.desktop {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background-image: url('/svg/bg-sidenav.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
.header {
  width: 100%;
}
.header.desktop {
  background-color: white;
  height: 64px;
}
.header.mobile {
  background-color: transparent;
  background-image: url('~@/assets/svg/bg-mobile-banner-2.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position-x: center;
  padding-bottom: 95px;
  position: relative;
  z-index: 10;
}
.rounded-tooltip {
  opacity: 1 !important;
  border-radius: 15px;
}
</style>
