var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.errorData
    ? _c(
        "v-container",
        { staticClass: "error-page" },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                wrap: "",
                "align-center": "",
                "justify-center": "",
                "fill-height": ""
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "gap-xs-3": "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "", lg9: "" } }, [
                        _c("h1", { staticClass: "starling-h1" }, [
                          _vm._v("Oops, something went wrong!")
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs12: "", lg9: "" } }, [
                        _c("p", { staticClass: "starling-body" }, [
                          _vm._v(
                            "Please contact the Dev Team if you think this error should not have happened."
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", lg9: "" } },
                        [_c("v-divider")],
                        1
                      ),
                      _vm.errorMessage
                        ? [
                            _c("v-flex", { attrs: { xs12: "", lg9: "" } }, [
                              _c("h4", { staticClass: "starling-h4" }, [
                                _vm._v("Description:")
                              ])
                            ]),
                            _c("v-flex", { attrs: { xs12: "", lg9: "" } }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "error-details starling-citation font-weight-bold pa-2"
                                },
                                [_vm._v(_vm._s(_vm.errorMessage))]
                              )
                            ])
                          ]
                        : _vm._e(),
                      _c("v-flex", { attrs: { xs12: "", lg9: "" } }, [
                        _c("h4", { staticClass: "starling-h4" }, [
                          _vm._v("Details:")
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs12: "", lg9: "" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "error-details starling-citation pa-2"
                          },
                          [_c("pre", [_vm._v(_vm._s(_vm.errorData))])]
                        )
                      ]),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            lg9: "",
                            "text-center": "",
                            "mt-3": ""
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                large: "",
                                round: "",
                                flat: "",
                                color: "primary"
                              },
                              on: { click: _vm.goBack }
                            },
                            [_vm._v("Go back")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { large: "", round: "", color: "primary" },
                              on: { click: _vm.goHome }
                            },
                            [_vm._v("Go to Homepage")]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }