var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "full-page",
      style: {
        "z-index": "1000",
        height: this.height ? this.height + "px" : "100%"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "full-page-centered",
          style: {
            "margin-left": _vm.$vuetify.breakpoint.mdAndUp ? " -300px" : ""
          }
        },
        [_vm._m(0)]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bounce" }, [
      _c("div", { staticClass: "double-bounce1" }),
      _c("div", { staticClass: "double-bounce2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }