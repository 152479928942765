




































import Vue from 'vue';
import BottomNavMobile from '@/views/components/navigation/bottom-nav-mobile.vue';
import CelebrationPopup from '@/views/components/utilities/celebration-popup.vue';
import LoadingView from '@/views/components/utilities/loading-view.vue';
import MaintenanceBar from '@/views/components/utilities/maintenance-bar.vue';
import NotificationBar from '@/views/components/utilities/notification-bar.vue';
import AppstoreBar from '@/views/components/utilities/appstore-bar.vue';
import SideNav from '@/views/components/navigation/side-nav.vue';
import WebinarBanner from '@/views/components/utilities/webinar-banner.vue';

export default Vue.extend({
  name: 'appContent',
  components: {
    BottomNavMobile,
    CelebrationPopup,
    LoadingView,
    MaintenanceBar,
    NotificationBar,
    AppstoreBar,
    SideNav,
    WebinarBanner,
  },
  data() {
    return {
      commentStatusesIntervalId: null,
    };
  },
  computed: {
    maintenance(): boolean {
      return this.$store.getters.maintenance;
    },
    loadingView(): boolean {
      return this.$store.state.loadingView;
    },
    isHome(): boolean {
      this.$log.debug('Current route', this.$route.name);
      return this.$route.name === 'home';
    },
    safeAreaTop(): number {
      return parseInt(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-top').replaceAll('px', '')) || 0;
    },
    extensionHeight(): number {
      if (this.$store.getters['navigation/tabs']) {
        return this.$vuetify.breakpoint.smAndDown ? 56 : 64;
      } else if (this.$store.getters['navigation/progress']) {
        return this.$vuetify.breakpoint.smAndDown ? 30 : 40;
      }
      return 0;
    },
    topNavHeight(): string {
      let toolbarHeight = 0;

      if (this.$vuetify.breakpoint.smAndDown) {
        toolbarHeight = 56;
      } else if (!this.isHome) {
        toolbarHeight = 64;
      }

      return `${toolbarHeight + this.extensionHeight + this.safeAreaTop}px`;
    },
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler(newVal) {
        this.$store.dispatch('favorites/getAllFavorites');
      },
    },
  },
  mounted() {
    this.$store.dispatch('getUser').then(() => {
      this.capacitorRegisterPushNotifications();
    });
    this.$data.commentStatusesIntervalId = setInterval(() => {
      this.$store.dispatch('comments/getCommentsStatusesByUser');
    }, 5 * 60 * 1000);
  },
  beforeDestroy() {
    if (this.$data.commentStatusesIntervalId) {
      clearInterval(this.$data.commentStatusesIntervalId);
    }
  },
});
