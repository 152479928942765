<template>
<v-layout row wrap class="checkup-reminder">
  <v-flex xs12>
    <v-progress-linear
            :class="value && value.remainingDays === 1 ? 'error-bg' : '' "
            style="border-radius: 10px;"
            :color="color"
            height="18"
            :value="percentage">
    </v-progress-linear>
  </v-flex>
  <v-flex xs12>
    <div class="checkup-text text-center" :style="textColor">
      <h2 class="checkup-subtext">
        <span v-html="this.daysText"></span>
      </h2>
    </div>
  </v-flex>
</v-layout>
</template>

<script>
export default {
  name: 'checkup-reminder',
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    active() {
      return this.value && this.value.remainingDays !== null;
    },
    percentage() {
      if (!this.active) return 6;

      if (this.value.remainingDays <= 0) {
        return 100;
      }

      return 100 * (this.value.remainingDays / this.value.totalDays);
    },
    daysText() {
      if (!this.active) return this.$t('app.home.user_stats_card.widgets.checkup_reminder.empty');

      if (this.value.remainingDays <= 0) {
        return this.$t('app.home.user_stats_card.widgets.checkup_reminder.past', { count: Math.abs(this.value.remainingDays) });
      }
      return this.$t('app.home.user_stats_card.widgets.checkup_reminder.future', { count: this.value.remainingDays });
    },
    color() {
      if (!this.active) return 'grey';

      if (this.value.remainingDays >= 4) {
        return 'success';
      } else if (this.value.remainingDays > 1) {
        return 'warning';
      } else if (this.value.remainingDays == 1) {
        return 'error';
      }

      return 'error';
    },
    textColor() {
      if (!this.active) {
        return 'color: var(--StarlingLightGrey)';
      }
      return 'color: var(--StarlingGrey)';
    },
  },
};
</script>

<style>

.checkup-text {
  margin-top: 10px;
  color: var(--StarlingDarkGrey);
}

@media (min-width: 600px) {
  .checkup-subtext {
    line-height: 20px;
    font-size: 15px;
    font-weight: normal;
  }

  .checkup-value {
    line-height: 32px;
    font-size: 28px;
  }
}

@media (max-width: 600px) {
  .checkup-subtext {
    line-height: 20px;
    font-size: 12px;
    font-weight: normal;
  }

  .checkup-value {
    line-height: 32px;
    font-size: 28px;
  }
}

.checkup-reminder .v-progress-linear .v-progress-linear__bar__determinate {
  border-radius: 15px;
}

.checkup-reminder .v-progress-linear .v-progress-linear__background {
  background-color: var(--StarlingLightGrey) !important;
}

.checkup-reminder .v-progress-linear.error-bg .v-progress-linear__background {
  background-color: var(--StarlingPaleRed) !important;
}
</style>
