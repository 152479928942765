var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        right: "",
        "offset-x": "",
        "open-on-click": "",
        "close-on-click": false,
        transition: "slide-x-transition",
        "open-delay": "200",
        "content-class": "popover-menu",
        value: true
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-chip",
                _vm._g(
                  {
                    staticClass: "popover-badge font-weight-bold",
                    attrs: { small: "" }
                  },
                  on
                ),
                [_vm._v(_vm._s(_vm.handle))]
              )
            ]
          }
        }
      ])
    },
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-2", on: { click: _vm.onClosePopover } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "starling-instructional mb-2",
                      staticStyle: { "padding-right": "30px" }
                    },
                    [
                      _c("strong", {
                        domProps: { innerHTML: _vm._s(_vm.title) }
                      })
                    ]
                  ),
                  _c("p", {
                    staticClass: "starling-text",
                    domProps: { innerHTML: _vm._s(_vm.content) }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticStyle: {
                        "margin-right": "-12px",
                        "margin-top": "25px"
                      },
                      attrs: {
                        absolute: "",
                        light: "",
                        fab: "",
                        top: "",
                        right: "",
                        flat: "",
                        small: ""
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }