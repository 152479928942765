<template>
  <v-timeline-item
      :color="item.color"
      :icon="item.icon"
      :small="$vuetify.breakpoint.mdAndDown"
      right
      fill-dot>

    <template v-slot:opposite v-if="$vuetify.breakpoint.lgAndUp">
    <sticky-note v-model="note"/>
    </template>
    <v-card>
      <v-card-title>
        <div style="width: 100%; text-align: left;">
          <span :class="`font-weight-bold ${item.color}--text`">{{ item.date | moment('LL') }}</span>
          <v-icon style="cursor:pointer; float:right;" @click="openNote" v-if="$vuetify.breakpoint.mdAndDown">mdi-note-outline</v-icon>
        </div>
      </v-card-title>
      <v-container>
        <v-layout>
          <v-flex xs12 lg10 class="text-center">
            <checkup-results-radarchart :values="item.values" :width="'100%'" :show-drilldown="false"/>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>

    <v-dialog v-model="openedNote" v-if="$vuetify.breakpoint.mdAndDown" style="
  margin: 0;
  box-shadow: none;">
      <sticky-note v-model="note"/>
    </v-dialog>
  </v-timeline-item>
</template>

<script>
import CheckupResultsRadarchart from '@/views/home/components/checkup-results-radarchart';
import StickyNote from '@/views/components/sticky-note/sticky-note';

export default {
  name: 'timeline-item-checkup',
  components: { StickyNote, CheckupResultsRadarchart },
  props: {
    item: Object,
  },
  data() {
    return {
      openedNote: false,
      note: this.item.note,
    };
  },
  methods: {
    openNote() {
      this.openedNote = true;
    },
  },
};
</script>

<style scoped>

</style>
