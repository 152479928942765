



































































































import Vue from 'vue';
import moment from 'moment';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import { WebinarRegistrant } from '@/scripts/store/modules/webinar/types';

export default Vue.extend({
  name: 'webinar-banner',
  components: { PrimaryButton },
  data() {
    return {
      dismissedRegistered: false,
      dismissedToday: false,
      dismissedLive: false,
      bannerWebinar: {} as WebinarRegistrant,
      loading: false,
      imageUrl: null as string | null,
      showRegisterMessage: false,
      showErrorMessage: false,
    };
  },
  computed: {
    showBanner(): boolean {
      if (!this.bannerWebinar.id) {
        return false;
      }
      return (
        (!this.dismissedToday && this.isToday) ||
        (!this.dismissedLive && this.isLive) ||
        (!this.dismissedRegistered && !this.isRegistered)
      );
    },
    isToday(): boolean {
      const { occurrunceTodayStartTime, joinUrl, isRegistered, occurrunceTodayEndTime } = this.bannerWebinar;
      return !!occurrunceTodayStartTime && !joinUrl && isRegistered && new Date(occurrunceTodayEndTime || 0) > new Date();
    },
    isLive(): boolean {
      return this.bannerWebinar.occurrunceTodayStartTime != null && this.bannerWebinar.joinUrl != null;
    },
    isRegistered(): boolean {
      return this.bannerWebinar.isRegistered && this.bannerWebinar.id != null;
    },
    webinarTopicTrimmed(): string {
      const topic = this.bannerWebinar?.topic || '';
      const firstPart = topic.split(':')[0];
      return firstPart.length > 30 ? firstPart.slice(0, 30) + '...' : firstPart;
    },
  },
  mounted() {
    this.$emit('update', this.showBanner);

    this.loading = true;

    this.$store.dispatch('webinar/getBanneredWebinar')
      .then(res => {
        this.bannerWebinar = res;
        this.generateImageUrl(this.bannerWebinar.bannerImage);
        this.dismissedRegistered = localStorage.getItem(`${this.$store.getters.user.id}_webinarBanner_register_${this.bannerWebinar.id}`) != null;
        this.dismissedToday = localStorage.getItem(`${this.$store.getters.user.id}_webinarBanner_today_${this.bannerWebinar.id}`) != null;
        this.dismissedLive = localStorage.getItem(`${this.$store.getters.user.id}_webinarBanner_live_${this.bannerWebinar.id}`) != null;
      }).finally(() => {
        this.loading = false;
      });
  },
  destroyed() {
    this.destroyImageUrl();
  },
  methods: {
    onBannerClose() {
      if (this.isToday) {
        localStorage.setItem(`${this.$store.getters.user.id}_webinarBanner_today_${this.bannerWebinar.id}`, '');
        this.dismissedToday = true;
      }
      if (this.isLive) {
        localStorage.setItem(`${this.$store.getters.user.id}_webinarBanner_live_${this.bannerWebinar.id}`, '');
        this.dismissedLive = true;
      }
      localStorage.setItem(`${this.$store.getters.user.id}_webinarBanner_register_${this.bannerWebinar.id}`, '');
      this.dismissedRegistered = true;
      this.$emit('update', false);
    },
    generateImageUrl(value?: string) {
      this.destroyImageUrl();
      if (value) {
        const blob = new Blob([ value ], { type: 'image/svg+xml' });
        this.imageUrl = URL.createObjectURL(blob);
      }
    },
    destroyImageUrl() {
      if (this.imageUrl) URL.revokeObjectURL(this.imageUrl);
      this.imageUrl = null;
    },
    formatDate(date: Date) {
      const locale = this.$i18n.locale;
      const momentDate = moment(date);
      return momentDate.locale(locale).format('MMM D');
    },
    formatTime(date: Date) {
      const options = { hour: 'numeric', minute: 'numeric', hour12: true };
      return new Intl.DateTimeFormat(this.$i18n.locale, options).format(new Date(date));
    },
    onConfirmContinue() {
      this.showRegisterMessage = false;
      this.onBannerClose();
    },
    handleRegistrationClick() {
      if (this.loading) return;
      this.loading = true;
      this.showErrorMessage = false;
      this.$store.dispatch('webinar/registerWebinar', this.bannerWebinar.id)
        .then(res => {
          if (res?.status >= 400) throw Error();
          this.bannerWebinar = res;
        }).catch(() => {
          this.showErrorMessage = true;
        }).finally(() => {
          this.loading = false;
          if (this.bannerWebinar.isRegistered) {
            this.showRegisterMessage = true;
          }
        });
    },
    handleJoin() {
      window.open(this.bannerWebinar.joinUrl, '_blank');
    },
  },
});
