




















import Vue from 'vue';
import ElementStyledContent from '@/views/products/components/element-styled-content.vue';
import { ITherapyContentCelebration, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'celebration-popup',
  components: {
    PrimaryButton,
    ElementStyledContent,
  },
  computed: {
    celebrationPopup(): ITherapyContentCelebration {
      return this.$store.getters['therapy/getCelebrationPopup'];
    },
    actionLabel(): string {
      return this.celebrationPopup?.styleContent?.find(item => item.key === StyleTypeEnum.ACTION_LABEL)?.content || this.$t('common.actions.great') as string;
    },
  },
  methods: {
    closePopup() {
      this.$store.commit('therapy/setCelebrationPopup', undefined);
    },
  },
});
