var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "checkup-reminder", attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c("v-progress-linear", {
            class: _vm.value && _vm.value.remainingDays === 1 ? "error-bg" : "",
            staticStyle: { "border-radius": "10px" },
            attrs: { color: _vm.color, height: "18", value: _vm.percentage }
          })
        ],
        1
      ),
      _c("v-flex", { attrs: { xs12: "" } }, [
        _c(
          "div",
          { staticClass: "checkup-text text-center", style: _vm.textColor },
          [
            _c("h2", { staticClass: "checkup-subtext" }, [
              _c("span", { domProps: { innerHTML: _vm._s(this.daysText) } })
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }