























import Vue from 'vue';

export default Vue.extend({
  name: 'badge-popover',
  props: {
    id: {
      type: String,
      required: true,
    },
    handle: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
  computed: {
    user(): any {
      return this.$store.getters.user;
    },
  },
  methods: {
    onClosePopover() {
      this.$store.dispatch('home/addPopoverConfig', this.id);
    },
  },
});
